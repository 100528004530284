const randomstring = require('randomstring')

export default class Question {
  constructor(question) {
    this.question = question
  }

  create() {
    return {
      id: randomstring.generate(10),
      question: this.question,
      answer: 'none',
      score: '0',
    }
  }
}
