<template>
  <div class="vueditor">
    <toolbar />
    <viewer />
  </div>
</template>

<script>
import toolbar from './components/toolbar'
import viewer from './components/viewer'

export default {
  components: {
    toolbar,
    viewer,
  },
}
</script>
